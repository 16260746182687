import React, { useEffect, useRef } from 'react';
import "../assets/css/SignaturePage.css";
import SignatureCanvas from 'react-signature-canvas'
import { Button, Grid, Typography } from '@mui/material';
import { withStyles } from "@material-ui/core/styles";
import LoaPreviewButton from '../components/LoaPreviewButton';
import Checkbox from '@mui/material/Checkbox';


export default function SignaturePage(props) {
    const [trimmedDataURL, setTrimmedDataURL] = React.useState(null);
    const [data, setData] = React.useState(props.data);
    const [clearResize, setClearResize] = React.useState(false);

    const label = { inputProps: { 'aria-label': 'controlled' } };
    const RedTextTypography = withStyles({
        root: {
            color: "red",
        }
    })(Typography);

    const DisclaimerTextTypography = withStyles({
        root: {
            color: "black",
            fontSize: "11px",
        }
    })(Typography);

    let sigPad = useRef(null);

    const clear = () => {
        sigPad.current.clear();
        props.setData({ ...props.data, signature: null });
    };

    const endStrokeUpdate = () => {
        let signatureStroke = sigPad.current.getTrimmedCanvas().toDataURL("image/png");
        setTrimmedDataURL(signatureStroke);
        props.setData({ ...props.data, signature: signatureStroke });
    };

    const handleResize = () => {
        if (!sigPad.current) return;
        const canvas = sigPad.current.getCanvas();
        const canvasContainer = canvas.parentElement;
        const { clientWidth, clientHeight } = canvasContainer;

        setClearResize(true);
        setTimeout(() => {
            setClearResize(false);
        }, 500);
    };

    useEffect(() => {
        window.addEventListener('orientationchange', handleResize);
        return () => {
            window.removeEventListener('orientationchange', handleResize);
        };
    }, []);

    return (
        <div>
            {!props.nextAttempt &&
                <RedTextTypography variant="body1" gutterBottom align="center" sx={{ mt: 2 }}>
                    Please Draw Your Signature to Continue
                </RedTextTypography>
            }
            <div className={"sigContainer"}>
                <SignatureCanvas penColor='black'
                    canvasProps={{ className: 'sigCanvas' }}
                    ref={sigPad}
                    onEnd={endStrokeUpdate}
                    clearOnResize={clearResize} />
            </div>

            <Grid container justifyContent="space-between">
                <Grid item xs={6}>
                    <Button
                        style={{
                            borderRadius: 3,
                            backgroundColor: '#ffeb3b',
                            color: 'black',
                        }}
                        variant="contained"
                        className={"buttons"} onClick={clear}
                        sx={{ mt: 2, mb: 1, ml: 1, px: 2 }}
                    >
                        Clear Signature
                    </Button>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <Button
                        style={{
                            borderRadius: 3,
                            width: '80%',
                        }}
                        variant="contained"
                        className={"buttons"}
                        onClick={props.handleNext}
                        sx={{ mt: 2, mb: 1, px: 4 }}
                        disabled={!(props.data?.signature && props.data.tob)}
                    >
                        {'Submit'}
                    </Button>
                </Grid>
            </Grid>

            <Typography gutterBottom verticalalign="text-bottom" align="center">
                I can confirm that I have read and understand the Terms of Business and <a href='https://flyyr-public.s3.eu-west-2.amazonaws.com/CPA-Privacy-Policy+(AF-24.10.23).pdf' target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                <Checkbox {...label} checked={props.data?.tob ?? false} onChange={(e) => props.setTob(e.target.checked)} />
            </Typography>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
                {props.data.lenders && <LoaPreviewButton client={props.client} data={props.data} />}
            </Grid>
            <DisclaimerTextTypography gutterBottom sx={{ mx: 2 }} verticalalign="text-bottom" align="center">
                Upon receiving your signature, we will contact your Car Lender to obtain your loan information. Additionally, we will write to your bank to request your statements. This information is essential to help us understand if you may not have been able to afford your monthly car loan repayments.
            </DisclaimerTextTypography>
            <DisclaimerTextTypography gutterBottom sx={{ mx: 2 }} verticalalign="text-bottom" align="center">
                Once we receive the information, we will carefully evaluate it and inform you of our assessment regarding the possibility of a potential claim. You will then have the opportunity to decide whether you would like to pursue a claim, if you do, this may involve engaging our recommended 3rd party CMC or handling the claim yourself.
            </DisclaimerTextTypography>
            <DisclaimerTextTypography gutterBottom sx={{ mx: 2 }} verticalalign="text-bottom" align="center">
                Please note, this is an inquiry, we are not making a complaint on your behalf at this stage. A complaint against your car lender will not commence until further permission is obtained from you. The enquiries we make on your behalf to determine the viability of a potential claim are free!
            </DisclaimerTextTypography>
            <DisclaimerTextTypography gutterBottom sx={{ mx: 2 }} verticalalign="text-bottom" align="center">
                A copy of the documents you need to sign can be found above. Once you have completed them, we will send you a copy for your records. Before signing any documents, we strongly encourage you to thoroughly read the Terms of Business provided.
            </DisclaimerTextTypography>
        </div>
    );
}
