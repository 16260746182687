import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { dobValidate } from '../utils/ValidateDob';

export default function ClientValidation(props) {
    const [dob, setDob] = useState(props.dob_prefill ?? '');
    const [dobDate, setDobDate] = useState('');
    const [dobMonth, setDobMonth] = useState('');
    const [dobYear, setDobYear] = useState('');
    const param = new URLSearchParams(window.location.search);
    const param_requirement = param.get("cid") && param.get("firstname") && param.get("lastname");

    const verification_page = (<>
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
        >
            <Grid item xs={12}>
                {"Hi " + param.get("firstname") + ", please provide your Date of Birth to validate our profile."}
            </Grid>
        </Grid>
        <Grid
            container
            spacing={1}
        >
            <Grid item xs={3}>
                <TextField
                    required
                    type="tel"
                    id="dob-1"
                    name="dob"
                    label="Day (dd)"
                    inputProps={{ maxLength: 2 }}
                    fullWidth
                    variant="standard"
                    value={dobDate}
                    error={(dob !== '' && !props.client)}
                    onChange={(e) => dobUpdate(e.target.value, 'dd')}
                    onBlur={(e) => dobUpdate(e.target.value, 'dd')}
                // helperText={(dobValidate(dob) == -1 && dob.length == 10) ? 'Incorrect Format' : ((dob !== '' && !props.client) ? 'Incorrect Date of Birth, Please check or dial 0161 758 5766' : '')}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    required
                    type="tel"
                    id="dob-2"
                    name="dob"
                    label="Month (mm)"
                    inputProps={{ maxLength: 2 }}
                    fullWidth
                    variant="standard"
                    value={dobMonth}
                    error={(dob !== '' && !props.client)}
                    onChange={(e) => dobUpdate(e.target.value, 'mm')}
                    onBlur={(e) => dobUpdate(e.target.value, 'mm')}
                // helperText={(dobValidate(dob) == -1 && dob.length == 10) ? 'Incorrect Format' : ((dob !== '' && !props.client) ? 'Incorrect Date of Birth, Please check or dial 0161 758 5766' : '')}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    required
                    type="tel"
                    id="dob-3"
                    name="dob"
                    label="Year (yyyy)"
                    inputProps={{ maxLength: 4 }}
                    fullWidth
                    variant="standard"
                    value={dobYear}
                    error={(dob !== '' && !props.client)}
                    onChange={(e) => dobUpdate(e.target.value, 'yyyy')}
                    onBlur={(e) => dobUpdate(e.target.value, 'yyyy')}
                // helperText={(dobValidate(dob) == -1 && dob.length == 10) ? 'Incorrect Format' : ((dob !== '' && !props.client) ? 'Incorrect Date of Birth, Please check or dial 0161 758 5766' : '')}
                />
            </Grid>
        </Grid>
    </>);

    const incorrect_url_page = (<>
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
        >
            <Grid item xs={12}>
                Sorry, Your URL is incorrect, Please check your email for a correct URL or dial 0161 758 5766 to register
            </Grid>
        </Grid>
    </>);

    const dobFormat = (e) => {
        let numChars = e.target.value?.length, thisVal = dob ? dob : "";

        if ((e.nativeEvent.inputType === "insertText") && (numChars === 2 || numChars === 5)) {
            thisVal = e.target.value;
            thisVal += '/';
            e.target.value = thisVal;
        } else {
            thisVal = e.target.value;
        }
        props.setValidationDob(thisVal);
        return thisVal;
    };

    const dobUpdate = (val, type) => {
        if (type === 'dd') {
            setDobDate(val);
            if (val.length == 2) {
                document.getElementById(`dob-2`).focus();
            }
        }
        else if (type === 'mm') {
            setDobMonth(val);
            if (val.length == 2) {
                document.getElementById(`dob-3`).focus();
            }
        }
        else if (type === 'yyyy') {
            setDobYear(val);
        }
        if (dobDate.length === 2 && dobMonth.length === 2 && dobYear.length === 4) {
            let fulldob = dobYear.toString() + '-' + dobMonth.toString() + '-' + dobDate.toString();
            setDob(fulldob);
            props.setValidationDob(fulldob);
        } else {
            let fulldob = dobYear.toString().padStart(4, '0') + '-' + dobMonth.toString().padStart(2, '0') + '-' + dobDate.toString().padStart(2, '0');
            setDob(fulldob);
            props.setValidationDob(fulldob);
        }
        return true;
    }

    useEffect(() => {
        if (props.dob_prefill) {
            let dobsplit = props.dob_prefill.split('-');
            if (dobsplit.length === 3 && dobDate === '' && dobMonth === '' && dobYear === '') {
                setDobDate(dobsplit[2]);
                setDobMonth(dobsplit[1]);
                setDobYear(dobsplit[0]);
            }
        }
    });


    return (<>
        {param_requirement ? verification_page : incorrect_url_page}
    </>
    )
}

