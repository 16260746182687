import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FormNavbar from './components/FormNavbar';
import FormFooter from './components/FormFooter';
import { verifyFirstStep, verifySecondStep, verifyThirdStep, verifyForthStep, verifyFifthStep, verifySeventhStep } from './utils/FormVerification';
import SignaturePage from './pages/SignaturePage';
import SignaturePageEnd from './pages/SignaturePageEnd';
import ThankyouPage from './pages/ThankyouPage';
import QuestionList from './pages/QuestionList';
import QuestionList2 from './pages/QuestionList2';
import QuestionList3 from './pages/QuestionList3';
import QuestionList3b from './pages/QuestionList3b';
import QuestionList4 from './pages/QuestionList4';
import QuestionList5 from './pages/QuestionList5';
import QuestionList6 from './pages/QuestionList6';
import axios from 'axios';
import LoadingSpinner from './components/LoadingSpinner';
import ClientValidation from './pages/ClientValidation';
import { makeStyles, Typography } from '@material-ui/core';
import signature_validation from './data/SignFileVersion.json'


export default function Checkout(props) {
  const theme = createTheme();
  const useStyles = makeStyles((theme) => ({
    completed: {
      color: 'green !important', // Change the icon color to green for completed steps
    },
  }));
  const steps = [...Array(8).keys()];

  const queryParameters = new URLSearchParams(window.location.search);
  const cid = queryParameters.get("cid");
  const first_name = queryParameters.get("firstname");
  const last_name = queryParameters.get("lastname");
  const restore_filling = queryParameters.get("restore");
  const agent = queryParameters.get("mcgagent");
  const dob_prefill = queryParameters.get("dob") ?? '';

  const [activeStep, setActiveStep] = React.useState(0);
  const [nextAttempt, setNextAttempt] = React.useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [validationDob, setValidationDob] = React.useState('');
  const [showButton, setShowButton] = React.useState(1);
  const [client, setClient] = React.useState({});
  const [data, setData] = React.useState({ 'vehicle_repay': '', 'cid': cid, 'agent': (agent ?? 0) });


  async function submitForm(final = false, question = -1, additional_field = {}) {
    let postdata = null;
    const processString = (inputString) => {
      if (/^\d+$/.test(inputString)) {
        return inputString;
      } else {
        return inputString.slice(0, -1);
      }
    }
    let new_lender = data.lenders.map(item => {
      return { ...item, id: processString(item.id) };
    });;
    if (final) {
      postdata = { ...data, question: question, signature_validation: { file: signature_validation, datetime: (new Date()).toISOString() }, ...additional_field, lenders: new_lender }
    } else {
      postdata = { ...data, question: question, ...additional_field, lenders: new_lender }
    }
    axios.post("https://affordability.myclaimgroup.co.uk/api/affordability_submitform", postdata, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        if (final)
          setActiveStep(100);
      }
      ).catch((error) => {
        console.log(error);
        if (final)
          setActiveStep(6);
      });
  }

  const setValidationDobChildProp = (dob) => {
    setValidationDob(dob);
    setData({ ...data, dob: dob });
  }

  const setTob = (value) => {
    setData({ ...data, tob: value });
  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <ClientValidation setValidationDob={setValidationDobChildProp} client={client} dob_prefill={dob_prefill}></ClientValidation>;
      case 1:
        return <QuestionList setData={setData} data={data} setCarFinance={setCarFinance} nextAttempt={nextAttempt}></QuestionList>;
      case 2:
        return <QuestionList2 setData={setData} data={data} nextAttempt={nextAttempt}></QuestionList2>;
      case 3:
        return queryParameters.get("version") != 'b' ?
          <QuestionList3 setData={setData} data={data} nextAttempt={nextAttempt}></QuestionList3> :
          <QuestionList3b setData={setData} data={data} nextAttempt={nextAttempt}></QuestionList3b>;
      case 4:
        return <QuestionList4 setData={setData} data={data} nextAttempt={nextAttempt}></QuestionList4>;
      case 5:
        return <QuestionList5 setData={setData} data={data} nextAttempt={nextAttempt} setShowButton={setShowButton}></QuestionList5>;
      case 6:
        return <QuestionList6 setData={setData} data={data} nextAttempt={nextAttempt}></QuestionList6>;
      case 7:
        return <SignaturePage setData={setData} data={data} nextAttempt={nextAttempt[3]} client={client} setTob={setTob} />;
      case 8:
        submitForm(true, -1);
        console.log("Performing Request");
        return <LoadingSpinner />;
      case 100:
        return <ThankyouPage client={client}></ThankyouPage>;
      default:
        console.log("Performing Request");
        return <LoadingSpinner />;
    }
  }

  const getStepContentEnd = (step) => {
    switch (step) {
      case 7:
        return <SignaturePageEnd setData={setData} data={data} nextAttempt={nextAttempt[3]} client={client} setTob={setTob} />;
      default:
        return <></>;
    }
  }

  const setCarFinance = (selection) => {
    if (selection === 1) {
      setActiveStep(activeStep + 1);
      setNextAttempt([0, 0, 0, 0, 0, 0, 0, 0]);
    } else {
      setData({ ...data, signature: null });
      submitForm(false, activeStep, { vehicle_finance: 0, vehicle_repay: "0" });
    }
  };

  const handleNext = () => {
    let updateNextAttemptRecord = nextAttempt;

    // Store data in local storage to cache filling
    if (activeStep >= 2) {
      localStorage.setItem("mcg_affordability_form_2", JSON.stringify(data));
    }

    if (activeStep === 0) {
      let verifyDob = validationDob;
      if (validationDob.split(';').length > 2) {
        let ymd = verifyDob.split(';');
        ymd = ymd[0].padStart(4, '0') + '-' + ymd[1].padStart(2, '0') + '-' + ymd[2].padStart(2, '0');
        setValidationDob(ymd);
        verifyDob = ymd;
      }

      verifyFirstStep(verifyDob, cid, first_name, last_name).then((e) => {
        if (e) {
          updateNextAttemptRecord[1] = 1;
          setActiveStep(activeStep + 1);
          setNextAttempt(updateNextAttemptRecord);
          setClient(e);

          // Check restore filling history
          let local_form = localStorage.getItem("mcg_affordability_form_2");
          if (local_form && restore_filling == 1) {
            setData(JSON.parse(local_form));
            console.log('Restored filling');
          }
        }
      }).catch((e) => setClient(false))
    } else if (activeStep === 1) {
      if (!verifySecondStep(data)) {
        updateNextAttemptRecord[2] = 1;
        setNextAttempt(updateNextAttemptRecord);
      } else {
        setData({ ...data, signature: null });
        submitForm(false, activeStep);
      }
    } else if (activeStep === 2 && !verifyThirdStep(data)) {
      updateNextAttemptRecord[3] = 1;
      setNextAttempt(updateNextAttemptRecord);
      setData({ ...data, 'lenders': [] });
      return;
    } else if (activeStep === 3 && !verifyForthStep(data)) {
      updateNextAttemptRecord[4] = 1;
      setNextAttempt(updateNextAttemptRecord);
    } else if (activeStep === 4 && !verifyFifthStep(data)) {
      updateNextAttemptRecord[5] = 1;
      setNextAttempt(updateNextAttemptRecord);
    } else if (activeStep === 6 && !verifySeventhStep(data)) {
      updateNextAttemptRecord[7] = 1;
      setNextAttempt(updateNextAttemptRecord);
    } else {
      setActiveStep(activeStep + 1);
      setNextAttempt([0, 0, 0, 0, 0, 0, 0, 0]);
      if ([2, 3, 4, 6].includes(activeStep)) {
        submitForm(false, activeStep);
      }
    }
    // Force the view to update 
    setData({ ...data });
    return;
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    setNextAttempt([0, 0, 0, 0, 0, 0, 0, 0]);
  };

  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <FormNavbar></FormNavbar>
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography variant="h6" gutterBottom verticalalign="text-bottom">
            {activeStep === 0 && 'Please start your enquiry about unaffordable car finance. It\'s free!'}
          </Typography>
          <Typography variant="h6" gutterBottom verticalalign="text-bottom">
            Car Finance Form
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 1, pb: 5, justifyContent: 'flex-end' }} alternativeLabel>
            {steps.map((label) => (
              <Step key={label} completed={label < activeStep}>
                <StepLabel StepIconProps={{ classes: { completed: classes.completed } }}></StepLabel>
              </Step>
            ))}
          </Stepper>
          {(
            <React.Fragment>
              {getStepContent(activeStep)}
              {showButton === 1 &&
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {activeStep > 2 && activeStep <= 8 && (
                    <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                      Back
                    </Button>
                  )}
                  {activeStep <= 8 && activeStep != 1 && (
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 3, ml: 1 }}
                      disabled={agent == 1 ? false : (activeStep === steps.length - 1 ? !(data?.signature && data.tob) : false)}
                    >
                      {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                    </Button>
                  )}
                </Box>
              }
              {getStepContentEnd(activeStep)}
            </React.Fragment>
          )}
        </Paper>
        <FormFooter />
      </Container>
    </ThemeProvider>
  );
}
