import Checkout from './Checkout';
import SignatureFile from './pages/SignatureFile';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ErrorPage from './pages/404';
import CheckoutFirstPart from './v2/CheckoutFirstPart'
import CheckoutSecondPart from './v2/CheckoutSecondPart'

const getMainModal = (file) => {
   if (file) {
      return (<SignatureFile file={file} />);
   } else {
      return (<Checkout />);
   }
}



const MainRouter = () => {
   const file = (new URLSearchParams(window.location.search)).get("file");
   return (
      <>
         <BrowserRouter>
            <Routes>
               <Route path="/">
                  <Route index element={getMainModal(file)} />
               </Route>

               <Route path="/v2" >
                  <Route path="1" element={<CheckoutFirstPart />} />
                  <Route path="2" element={<CheckoutSecondPart />} />
               </Route>
               <Route path="*" element={<ErrorPage />} />
            </Routes>
         </BrowserRouter>
      </>
   );
};


export default MainRouter;
