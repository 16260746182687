import * as React from 'react';
import Grid from '@mui/material/Grid';
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { vehicle_repay_range, salary_range, pension_range } from '../data/SelectRange';

export default function QuestionList(props) {
    const [data, setData] = React.useState(props.data);

    const setLenderRepayment = (lender_id, repayment) => {
        let newData = { ...data };
        let newlender = [];
        props.data.lenders.forEach(element => {
            if (element.id == lender_id) 
                newlender.push({...element, 'repayment': repayment});
            else
                newlender.push(element);
        });
        newData['lenders'] = newlender;
        newData['vehicle_repay_' + lender_id] = repayment;
        setData(newData);
        props.setData(newData);
    }

    const setQuestionAnswer = (key, answer) => {
        let newData = { ...data };
        newData[key] = answer;
        setData(newData);
        props.setData(newData);
    }


    return (
        <React.Fragment>
            <Grid container spacing={0}>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={12}>
                        <Typography gutterBottom sx={{ mx: 2 }} verticalalign="text-bottom" variant="h6" color="blue">
                            If you can't remember, don't worry, please provide your best recollections
                        </Typography>
                        {data.lenders.length > 1 &&
                            <Typography gutterBottom sx={{ mx: 2, fontStyle: 'italic' }} verticalalign="text-bottom" variant="body2" color="blue">
                                *We understand your personal circumstances may vary for each loan. Simply enter the most suitable answers.
                            </Typography>
                        }
                    </Grid>
                    {
                        data.lenders.map((element, index) => element.id != 217 &&
                            <React.Fragment key={element.title}>
                                <Grid item xs={6}>
                                    <Typography gutterBottom sx={{ mx: 2 }} verticalalign="text-bottom" color={(props.nextAttempt[4] && data['vehicle_repay_' + element.id] == null) ? "red" : "black"}>
                                        3{String.fromCharCode(index + 97)}. Roughly, what was your monthly payment?
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} justify="center" align="center">
                                    <FormControl variant="standard" sx={{ mx: 2 }} fullWidth>
                                        <InputLabel id="vehicle_repay_select_label">{element.title}</InputLabel>
                                        <Select
                                            labelId="vehicle_repay_select_label"
                                            id={"vehicle_repay_select_" + element.title}
                                            label={"vehicle_repay_" + element.title}

                                            value={data['vehicle_repay_' + element.id] ?? ''}
                                            onChange={(e) => setLenderRepayment(element.id, e.target.value)}
                                        >
                                            {vehicle_repay_range.map((e) => (<MenuItem value={e.key} key={e.key}>£{e.text}</MenuItem>))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                            </React.Fragment>
                        )
                    }

                    <Grid item xs={6}>
                        <Typography gutterBottom sx={{ mx: 2 }} verticalalign="text-bottom" color={(props.nextAttempt[4] && !data.employment) ? "red" : "black"}>
                            4. What was your employment circumstances?
                        </Typography>
                    </Grid>
                    <Grid item xs={6} justify="center" align="center">
                        <FormControl variant="standard" sx={{ mx: 2 }} fullWidth>
                            <InputLabel id="employment_select_label">Employment Status</InputLabel>
                            <Select
                                id="employment_select"
                                label="employment_select_label"
                                value={data.employment ?? ''}
                                onChange={(e) => setQuestionAnswer('employment', e.target.value)}
                            >
                                <MenuItem value={'Employed Full Time'}>Employed Full Time</MenuItem>
                                <MenuItem value={'Employed Part Time'}>Employed Part Time</MenuItem>
                                <MenuItem value={'Self Employed'}>Self Employed</MenuItem>
                                <MenuItem value={'Retired'}>Retired</MenuItem>
                                <MenuItem value={'Unemployed'}>Unemployed</MenuItem>
                                <MenuItem value={'Student'}>Student</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {['Employed Full Time', 'Employed Part Time', 'Self Employed', 'Retired'].includes(data.employment) &&
                        <>
                            <Grid item xs={6}>
                                <Typography gutterBottom sx={{ mx: 2 }} verticalalign="text-bottom" color={(props.nextAttempt[4] && !data.dalary) ? "red" : "black"}>
                                    5. {data.employment === 'Retired' ? 'What was your monthly pension?' : 'What was your average monthly take-home pay?'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} justify="center" align="center">
                                <FormControl variant="standard" sx={{ mx: 2 }} fullWidth>
                                    <InputLabel id="salary_select_label">{data.employment === 'Retired' ? 'Pension' : 'Salary'}</InputLabel>
                                    <Select
                                        labelId="salary_select_label"
                                        id={"salary_select"}
                                        label={data.employment === 'Retired' ? 'pension' : 'salary'}

                                        value={data.salary ?? ''}
                                        onChange={(e) => setQuestionAnswer('salary', e.target.value)}
                                    >
                                        {(data.employment === 'Retired' ? pension_range : salary_range).map((e) => (<MenuItem value={e.key} key={e.key}>£{e.text}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </>
                    }

                </Grid>
            </Grid>
        </React.Fragment>
    );
}
