export const vehicle_repay_range = [
    { text: '0-150', key: 1 },
    { text: '151-200', key: 151 },
    { text: '201-250', key: 201 },
    { text: '251-300', key: 251 },
    { text: '301-350', key: 301 },
    { text: '351-400', key: 351 },
    { text: '401-450', key: 401 },
    { text: '451-500', key: 451 },
    { text: '501-550', key: 501 },
    { text: '551-600', key: 551 },
    { text: '601-650', key: 601 },
    { text: '651-700', key: 651 },
    { text: '701-750', key: 701 },
    { text: '751-800', key: 751 },
    { text: '801-850', key: 801 },
    { text: '851-900', key: 851 },
    { text: '901-950', key: 901 },
    { text: '951-1000', key:951 },
    { text: '1000+', key: 1001 }
];

export const salary_range = [
    { text: '0-500', key: 1 },
    { text: '501-700', key: 500 },
    { text: '701-800', key: 700 },
    { text: '801-900', key: 800 },
    { text: '901-1000', key: 900 },
    { text: '1001-1100', key: 1000 },
    { text: '1101-1200', key: 1100 },
    { text: '1201-1300', key: 1200 },
    { text: '1301-1400', key: 1300 },
    { text: '1401-1500', key: 1400 },
    { text: '1501-1600', key: 1500 },
    { text: '1701-1800', key: 1700 },
    { text: '1801-1900', key: 1800 },
    { text: '1901-2000', key: 1900 },
    { text: '2001-2100', key: 2000 },
    { text: '2101-2200', key: 2100 },
    { text: '2201-2300', key: 2200 },
    { text: '2301-2400', key: 2300 },
    { text: '2401-2500', key: 2400 },
    { text: '2501-2600', key: 2500 },
    { text: '2601-2700', key: 2600 },
    { text: '2701-2800', key: 2700 },
    { text: '2801-2900', key: 2800 },
    { text: '2901-3000', key: 2900 },
    { text: '3000+', key: 3000 },
];

export const pension_range = [
    { text: '0-150', key: 1 },
    { text: '151-200', key: 151 },
    { text: '201-250', key: 201 },
    { text: '251-300', key: 251 },
    { text: '301-350', key: 301 },
    { text: '351-400', key: 351 },
    { text: '401-450', key: 401 },
    { text: '451-500', key: 451 },
    { text: '501-550', key: 501 },
    { text: '551-600', key: 551 },
    { text: '601-650', key: 601 },
    { text: '651-700', key: 651 },
    { text: '701-800', key: 701 },
    { text: '801-900', key: 801 },
    { text: '901-1000', key: 901 },
    { text: '1000', key: 1000 }
];

export const debt_range = [
    { text: '0-150', key: 1 },
    { text: '151-250', key: 151 },
    { text: '251-350', key: 251 },
    { text: '351-450', key: 351 },
    { text: '451-550', key: 451 },
    { text: '551-650', key: 551 },
    { text: '651-750', key: 651 },
    { text: '750+', key: 750 }
];

export const rent_range = [
    { text: '0-100', key: 1 },
    { text: '100-200', key: 101 },
    { text: '200-300', key: 201 },
    { text: '300-400', key: 301 },
    { text: '400-500', key: 401 },
    { text: '500-600', key: 501 },
    { text: '600-700', key: 601 },
    { text: '700-800', key: 701 },
    { text: '800-900', key: 801 },
    { text: '900-1000', key: 901 },
    { text: '1000-1100', key: 1001 },
    { text: '1100-1200', key: 1101 },
    { text: '1200+', key: 1200 },
];
