import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FormNavbar from '../components/FormNavbar';
import FormFooter from '../components/FormFooter';
import ThankyouPage from '../pages/ThankyouPage';
import QuestionList3 from '../pages/QuestionList3';
import QuestionList3b from '../pages/QuestionList3b';
import QuestionList4 from '../pages/QuestionList4';
import axios from 'axios';
import LoadingSpinner from '../components/LoadingSpinner';
import { makeStyles, Typography } from '@material-ui/core';
import signature_validation from '../data/SignFileVersion.json'
import { verifyForthStep, verifyFifthStep } from '../utils/FormVerification';

export default function CheckoutSecondPart(props) {
  const theme = createTheme();
  const useStyles = makeStyles((theme) => ({
    completed: {
      color: 'green !important', // Change the icon color to green for completed steps
    },
  }));
  const steps = [...Array(3).keys()];

  const queryParameters = new URLSearchParams(window.location.search);
  const cid = queryParameters.get("cid");
  const agent = queryParameters.get("mcgagent");
  const queryData = queryParameters.get("data") ?? { 'vehicle_repay': '', 'cid': cid, 'agent': (agent ?? 0) };

  const [activeStep, setActiveStep] = React.useState(0);
  const [nextAttempt, setNextAttempt] = React.useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [client, setClient] = React.useState({});

  let initData = { 'vehicle_repay': '', 'cid': cid, 'agent': (agent ?? 0) }
  try {
    if (queryData) {
      initData = JSON.parse(atob(queryData));
      initData['vehicle_finance'] = '1';
      initData['agent'] = 0;
    }
  } catch {
    window.location.href = '/404'
  }
  const [data, setData] = React.useState(initData);
  const processString = (inputString) => {
    if (/^\d+$/.test(inputString)) {
      return inputString;
    } else {
      return inputString.slice(0, -1);
    }
  }


  async function submitForm(final = false, question = -1, additional_field = {}) {
    let postdata = null;

    let new_lender = {}
    if (data.lenders) {
      new_lender = data.lenders.map(item => {
        return { ...item, id: processString(item.id) };
      });
    }

    if (final) {
      postdata = { ...data, question: question, signature_validation: { file: signature_validation, datetime: (new Date()).toISOString() }, ...additional_field, lenders: new_lender, version: '2' }
    } else {
      postdata = { ...data, question: question, ...additional_field, lenders: new_lender, version: '2' }
    }
    axios.post("https://affordability.myclaimgroup.co.uk/api/affordability_submitform", postdata, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        if (final)
          setActiveStep(100);
      }
      ).catch((error) => {
        console.log(error);
        if (final)
          setActiveStep(6);
      });
  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return queryParameters.get("version") != 'b' ?
          <QuestionList3 setData={setData} data={data} nextAttempt={nextAttempt}></QuestionList3> :
          <QuestionList3b setData={setData} data={data} nextAttempt={nextAttempt}></QuestionList3b>;
      case 1:
        return <QuestionList4 setData={setData} data={data} nextAttempt={nextAttempt}></QuestionList4>;
      case 2:
        submitForm(true, -1);
        console.log("Performing Request");
        return <LoadingSpinner />;
      case 100:
        return <ThankyouPage client={client}></ThankyouPage>;
      default:
        console.log("Performing Request");
        return <LoadingSpinner />;
    }
  }

  const handleNext = () => {
    let updateNextAttemptRecord = nextAttempt;

    if (activeStep === 0 && !verifyForthStep(data)) {
      updateNextAttemptRecord[4] = 1;
      setNextAttempt(updateNextAttemptRecord);
      setData({ ...data });
      return;
    } else if (activeStep === 1 && !verifyFifthStep(data)) {
      updateNextAttemptRecord[5] = 1;
      setNextAttempt(updateNextAttemptRecord);
      setData({ ...data });
      return;
    } else {
      setActiveStep(activeStep + 1);
      setNextAttempt([0, 0, 0, 0, 0, 0, 0, 0]);
      if ([0, 1].includes(activeStep)) {
        submitForm(false, activeStep);
      }
    }
    // Force the view to update 
    setData({ ...data });
    return;
  };

  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <FormNavbar></FormNavbar>
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography variant="h6" gutterBottom verticalalign="text-bottom">
            Car Finance Form
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 1, pb: 5, justifyContent: 'flex-end' }} alternativeLabel>
            {steps.map((label) => (
              <Step key={label} completed={label < activeStep}>
                <StepLabel StepIconProps={{ classes: { completed: classes.completed } }}></StepLabel>
              </Step>
            ))}
          </Stepper>
          {(
            <React.Fragment>
              {getStepContent(activeStep)}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep <= 8 && (
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                  </Button>
                )}
              </Box>
            </React.Fragment>
          )}
        </Paper>
        <FormFooter />
      </Container>
    </ThemeProvider>
  );
}
