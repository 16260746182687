import * as React from 'react';
import Grid from '@mui/material/Grid';
import { Divider, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { vehicle_repay_range, salary_range, pension_range } from '../data/SelectRange';

export default function QuestionList(props) {
    const [data, setData] = React.useState(props.data);

    const setLenderRepayment = (lender_id, repayment) => {
        let newData = { ...data };
        let newlender = [];
        props.data.lenders.forEach(element => {
            if (element.id == lender_id)
                newlender.push({ ...element, 'repayment': repayment });
            else
                newlender.push(element);
        });
        newData['lenders'] = newlender;
        newData['vehicle_repay_' + lender_id] = repayment;
        setData(newData);
        props.setData(newData);
    }

    const setEmployer = (lender_id, employer) => {
        let newData = { ...data };
        let newlender = [];
        props.data.lenders.forEach(element => {
            if (element.id == lender_id)
                newlender.push({ ...element, 'employer': employer });
            else
                newlender.push(element);
        });
        newData['lenders'] = newlender;
        newData['employment_' + lender_id] = employer;
        setData(newData);
        props.setData(newData);
    }


    const setSalary = (lender_id, salary) => {
        let newData = { ...data };
        let newlender = [];
        props.data.lenders.forEach(element => {
            if (element.id == lender_id)
                newlender.push({ ...element, 'salary': salary });
            else
                newlender.push(element);
        });
        newData['lenders'] = newlender;
        newData['salary_' + lender_id] = salary;
        setData(newData);
        props.setData(newData);
    }


    const setQuestionAnswer = (key, answer) => {
        let newData = { ...data };
        newData[key] = answer;
        setData(newData);
        props.setData(newData);
    }

    return (
        <React.Fragment>
            <Grid container spacing={0}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography gutterBottom sx={{ mx: 2 }} verticalalign="text-bottom" variant="h6" color="blue">
                            If you can't remember, don't worry, please provide your best recollections
                        </Typography>
                        {data.lenders.length > 1 &&
                            <Typography gutterBottom sx={{ mx: 2, fontStyle: 'italic' }} verticalalign="text-bottom" variant="body2" color="blue">
                                *We understand your personal circumstances may vary for each loan. Simply enter the most suitable answers.
                            </Typography>
                        }
                    </Grid>
                    {
                        data.lenders.map((element, index) => element.id != 217 &&
                            <React.Fragment key={element.title}>
                                <Grid item xs={6}>
                                    <Typography gutterBottom sx={{ mx: 2 }} verticalalign="text-bottom" color={(props.nextAttempt[4] && data['vehicle_repay_' + element.id] == null) ? "red" : "black"}>
                                        3{String.fromCharCode(index + 97)}. Roughly, what was your monthly payment?
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} justify="center" align="center">
                                    <FormControl variant="standard" sx={{ mx: 2, minWidth: 200 }}>
                                        <InputLabel id="vehicle_repay_select_label">{element.title}</InputLabel>
                                        <Select
                                            labelId="vehicle_repay_select_label"
                                            id={"vehicle_repay_select_" + element.title}
                                            label={"vehicle_repay_" + element.title}

                                            value={data['vehicle_repay_' + element.id] ?? ''}
                                            onChange={(e) => setLenderRepayment(element.id, e.target.value)}
                                        >
                                            {vehicle_repay_range.map((e) => (<MenuItem value={e.key} key={e.key}>£{e.text}</MenuItem>))}
                                        </Select>
                                    </FormControl>
                                </Grid>


                                <Grid item xs={6}>
                                    <Typography gutterBottom sx={{ mx: 2 }} verticalalign="text-bottom" color={(props.nextAttempt[4] && !data['employment_' + element.id]) ? "red" : "black"}>
                                        4{String.fromCharCode(index + 97)}. What was your employment circumstances?
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} justify="center" align="center">
                                    <FormControl variant="standard" sx={{ mx: 2, minWidth: 200 }}>
                                        <InputLabel id="employment_select_label">Employment Status</InputLabel>
                                        <Select
                                            id="employment_select"
                                            label="employment_select_label"
                                            value={data['employment_' + element.id] ?? ''}
                                            onChange={(e) => setEmployer(element.id, e.target.value)}
                                        >
                                            <MenuItem value={'Employed Full Time'}>Employed Full Time</MenuItem>
                                            <MenuItem value={'Employed Part Time'}>Employed Part Time</MenuItem>
                                            <MenuItem value={'Self Employed'}>Self Employed</MenuItem>
                                            <MenuItem value={'Retired'}>Retired</MenuItem>
                                            <MenuItem value={'Unemployed'}>Unemployed</MenuItem>
                                            <MenuItem value={'Student'}>Student</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {['Employed Full Time', 'Employed Part Time', 'Self Employed', 'Retired'].includes(data['employment_' + element.id]) &&
                                    <>
                                        <Grid item xs={6}>
                                            <Typography gutterBottom sx={{ mx: 2 }} verticalalign="text-bottom" color={(props.nextAttempt[4] && !data.dalary) ? "red" : "black"}>
                                                5{String.fromCharCode(index + 97)}. {data['employment_' + element.id] === 'Retired' ? 'What was your monthly pension?' : 'What was your average monthly take-home pay?'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} justify="center" align="center">
                                            <FormControl variant="standard" sx={{ mx: 2, minWidth: 200 }}>
                                                <InputLabel id="salary_select_label">{data['employment_' + element.id] === 'Retired' ? 'Pension' : 'Salary'}</InputLabel>
                                                <Select
                                                    labelId="salary_select_label"
                                                    id={"salary_select"}
                                                    label={data['employment_' + element.id] === 'Retired' ? 'pension' : 'salary'}

                                                    value={data['salary_' + element.id] ?? ''}
                                                    onChange={(e) => setSalary(element.id, e.target.value)}
                                                >
                                                    {(data['employment_' + element.id] === 'Retired' ? pension_range : salary_range).map((e) => (<MenuItem value={e.key} key={e.key}>£{e.text}</MenuItem>))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </>
                                }

                                {data.lenders.length - 1 != index &&
                                    <Grid item xs={12}>
                                        <Divider sx={{ mt: 2 }} />
                                    </Grid>
                                }

                            </React.Fragment>
                        )
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
