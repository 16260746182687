import * as React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import banks from '../data/Bank.json'
import pcplenders from '../data/PcpLenders.json'

const names = pcplenders;

export default function QuestionList2(props) {
    const [data, setData] = React.useState(props.data);

    const setQuestionAnswer = (key, answer) => {
        let newData = { ...data, ...props.data };
        newData[key] = answer;
        setData(newData);
        props.setData(newData);
    }

    const handleSelection = (event, value) => {
        setQuestionAnswer('lenders', value)
    }

    return (
        <React.Fragment>
            <Grid container spacing={0}>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={6}>
                        <Typography gutterBottom sx={{ mx: 2 }} verticalalign="text-bottom" color={(props.data.lenders !== undefined && !data.lenders?.length) >= 1 ? "red" : "black"}>
                            {!props.questionNumber && '2. '}Who was your car lender?<br />
                            Please Select up to a maximum of 3 car lenders
                        </Typography>
                    </Grid>
                    <Grid item xs={6} justify="center" align="center">
                        <Autocomplete
                            multiple
                            id="tags-outlined"
                            options={names}
                            getOptionLabel={(option) => option.title}
                            limitTags={3}
                            filterSelectedOptions={true}
                            onChange={handleSelection}
                            getOptionDisabled={(options) => (data.lenders?.length >= 3 ? true : false)}
                            value={data.lenders ?? []}
                            key="pcp-lenders-autocomplete"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    key={params.id}
                                    label="Lenders"
                                    placeholder="Lenders"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                {
                    (props.nextAttempt[3] == 1 && data.lenders?.length == 1 && data.lenders[0]?.id == 217) && <>
                        <Typography align="center" sx={{ mt: 4 }} color="red">
                            Thank you for your information. Sorry we are not able to help with other lenders at the moment. We will inform you if this changes.
                        </Typography>
                    </>
                }
            </Grid>
        </React.Fragment>
    );
}
