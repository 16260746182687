// images.js
import logo from '../assets/logo.webp';
import logo2 from '../assets/logo2.png';
import logo3 from '../assets/logo3.png';
import logo4 from '../assets/logo4.png';

const logos = {
    logo,
    logo2,
    logo3,
    logo4
};

export default logos;
