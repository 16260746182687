import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FormNavbar from '../components/FormNavbar';
import FormFooter from '../components/FormFooter';
import SignaturePage from '../pages/SignaturePage';
import SignaturePageEnd from '../pages/SignaturePageEnd';
import ThankyouPageV2 from './ThankyouPageV2';
import { verifyFirstStep, verifySecondStep, verifyThirdStep, verifySeventhStep } from '../utils/FormVerification';
import QuestionList from '../pages/QuestionList';
import QuestionList2 from '../pages/QuestionList2';
import QuestionList6 from '../pages/QuestionList6';
import axios from 'axios';
import LoadingSpinner from '../components/LoadingSpinner';
import ClientValidation from '../pages/ClientValidation';
import { makeStyles, Typography } from '@material-ui/core';
import signature_validation from '../data/SignFileVersion.json'

export default function CheckoutFirstPart(props) {
  const theme = createTheme();
  const useStyles = makeStyles((theme) => ({
    completed: {
      color: 'green !important', // Change the icon color to green for completed steps
    },
  }));
  const steps = [...Array(4).keys()];
  const classes = useStyles();

  const queryParameters = new URLSearchParams(window.location.search);
  const cid = queryParameters.get("cid");
  const first_name = queryParameters.get("firstname");
  const last_name = queryParameters.get("lastname");
  const restore_filling = queryParameters.get("restore");
  const agent = queryParameters.get("mcgagent");
  const dob_prefill = queryParameters.get("dob") ?? '';

  const [activeStep, setActiveStep] = React.useState(0);
  const [nextAttempt, setNextAttempt] = React.useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [validationDob, setValidationDob] = React.useState('');
  const [client, setClient] = React.useState({});
  const [data, setData] = React.useState({ 'vehicle_repay': '', 'cid': cid, 'agent': (agent ?? 0) });
  const processString = (inputString) => {
    if (/^\d+$/.test(inputString)) {
      return inputString;
    } else {
      return inputString.slice(0, -1);
    }
  }

  const removeDuplicatesByKey = (arr, key) => {
    if (Object.keys(arr).length == 0) {
      return {};
    }
    const uniqueKeys = new Set();
    return arr.filter(obj => {
      if (!uniqueKeys.has(obj[key])) {
        uniqueKeys.add(obj[key]);
        return true;
      }
      return false;
    });
  }

  async function submitForm(final = false, question = -1, additional_field = {}) {

    let new_lender = {}
    if (data.lenders) {
      new_lender = data.lenders.map(item => {
        return { ...item, id: processString(item.id) };
      });
    }

    new_lender = removeDuplicatesByKey(new_lender)

    let postdata = {};
    if (final) {
      postdata = { ...data, question: question, signature_validation: { file: signature_validation, datetime: (new Date()).toISOString() }, ...additional_field, lenders: new_lender, version: '2' }
    } else {
      postdata = { ...data, question: question, ...additional_field, lenders: new_lender, version: '2' }
    }
    axios.post("https://affordability.myclaimgroup.co.uk/api/affordability_submitform", postdata, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        if (final)
          setActiveStep(100);
      }
      ).catch((error) => {
        console.log(error);
        if (final)
          setActiveStep(4);
      });
  }

  const setValidationDobChildProp = (dob) => {
    setValidationDob(dob);
    setData({ ...data, dob: dob });
  }

  const setTob = (value) => setData({ ...data, tob: value });


  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <QuestionList setData={setData} data={data} setCarFinance={setCarFinance} nextAttempt={nextAttempt}></QuestionList>;
      case 1:
        return <ClientValidation setValidationDob={setValidationDobChildProp} client={client} dob_prefill={dob_prefill}></ClientValidation>;
      case 2:
        return <>
          <QuestionList2 setData={setData} data={data} nextAttempt={nextAttempt} questionNumber={true}></QuestionList2>
          <QuestionList6 setData={setData} data={data} nextAttempt={nextAttempt}></QuestionList6>
        </>;
      case 3:
        return <SignaturePage setData={setData} data={data} nextAttempt={nextAttempt[3]} client={client} setTob={setTob} handleNext={handleNext} />;
      case 4:
        submitForm(true, -1);
        console.log("Performing Request");
        return <LoadingSpinner />;
      case 100:
        return <ThankyouPageV2 client={client} data={data}></ThankyouPageV2>;
      default:
        console.log("Performing Request");
        return <LoadingSpinner />;
    }
  }

  const getStepContentEnd = (step) => {
    switch (step) {
      case 4:
        return <SignaturePageEnd setData={setData} data={data} nextAttempt={nextAttempt[3]} client={client} setTob={setTob} />;
      default:
        return <></>;
    }
  }

  const setCarFinance = (selection) => {
    if (selection === 1) {
      setActiveStep(activeStep + 1);
      setNextAttempt([0, 0, 0, 0, 0, 0, 0, 0]);
      submitForm(false, activeStep, { vehicle_finance: 1, vehicle_repay: "1" });
    } else {
      setData({ ...data, signature: null });
      submitForm(false, activeStep, { vehicle_finance: 0, vehicle_repay: "0" });
    }
  };

  const handleNext = () => {
    let updateNextAttemptRecord = nextAttempt;

    if (activeStep === 1) {
      let verifyDob = validationDob;
      if (validationDob.split(';').length > 2) {
        let ymd = verifyDob.split(';');
        ymd = ymd[0].padStart(4, '0') + '-' + ymd[1].padStart(2, '0') + '-' + ymd[2].padStart(2, '0');
        setValidationDob(ymd);
        verifyDob = ymd;
      }

      verifyFirstStep(verifyDob, cid, first_name, last_name).then((e) => {
        if (e) {
          updateNextAttemptRecord[1] = 1;
          setActiveStep(activeStep + 1);
          setNextAttempt(updateNextAttemptRecord);
          setClient(e);
          // Check restore filling history
          // let local_form = localStorage.getItem("mcg_affordability_form");
          // if (local_form && restore_filling == 1) {
          //   setData(JSON.parse(local_form));
          //   console.log('Restored filling');
          // }
        }
      }).catch((e) => setClient(false))
    } else if (activeStep === 1) {
      if (!verifySecondStep(data)) {
        updateNextAttemptRecord[2] = 1;
        setNextAttempt(updateNextAttemptRecord);
      } else {
        setData({ ...data, signature: null });
        submitForm(false, activeStep);
      }
    } else if (activeStep === 2 && !verifyThirdStep(data)) {
      updateNextAttemptRecord[3] = 1;
      setNextAttempt(updateNextAttemptRecord);
      setData({ ...data, 'lenders': [] });
      return;
    } else if (activeStep === 2 && !verifySeventhStep(data)) {
      updateNextAttemptRecord[7] = 1;
      setNextAttempt(updateNextAttemptRecord);
    } else {
      setActiveStep(activeStep + 1);
      setNextAttempt([0, 0, 0, 0, 0, 0, 0, 0]);
      if ([2, 3].includes(activeStep)) {
        submitForm(false, activeStep);
      }
    }

    // Force the view to update 
    setData({ ...data });
    return;
  };


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <FormNavbar></FormNavbar>
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <Paper variant="" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography variant="h6" gutterBottom verticalalign="text-bottom">
            Car Finance Form
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 1, pb: 5, justifyContent: 'flex-end' }} alternativeLabel>
            {steps.map((label) => (
              <Step key={label} completed={label < activeStep}>
                <StepLabel StepIconProps={{ classes: { completed: classes.completed } }}></StepLabel>
              </Step>
            ))}
          </Stepper>
          {(
            <React.Fragment>
              {getStepContent(activeStep)}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep <= 8 && activeStep != 0 && (
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 3, ml: 1 }}
                    disabled={agent == 1 ? false : (activeStep === steps.length - 1 ? !(data?.signature && data.tob) : false)}
                  >
                    {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                  </Button>
                )}
              </Box>
              {getStepContentEnd(activeStep)}
            </React.Fragment>
          )}
        </Paper>
        <FormFooter />
      </Container>
    </ThemeProvider>
  );
}
