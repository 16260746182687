import * as React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import thumbup from '../assets/thumbs_up.png'

export default function ThankyouPage(props) {
    return (
        <div>
            <Grid container justifyContent="center">
                <Grid item>
                    <img src={thumbup} alt="Successful!" />
                </Grid>
            </Grid>
            <Typography variant="h5" align="center" color="green">
                Thank you {props.client?.first_name} {props.client?.last_name}, we have received your application.
            </Typography>
            {/* <Typography variant="h5" align="center" sx={{ mt: 2 }} color="green">
                We have sent a confirmation email to {props.client.email} (Please check your junk).
            </Typography> */}
            <Grid container sx={{ mt: 2 }}>
                <Grid item xs={12} justify="center" align="center">
                    <Button align="center" variant="contained" href="https://www.myclaimgroup.co.uk">Back To Home Page</Button>
                </Grid>
            </Grid>
        </div>
    );
}