import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Box } from '@mui/system';
import logos from './FormNavbarLogo.js'
import '../assets/css/Global.css'

const queryParameters = new URLSearchParams(window.location.search)

export default function FormNavbar(props) {
    const getNavImage = () => {
        let intro = queryParameters.get("introducer");
        let path = logos.logo;
        if (intro == 'impakt')
            path = logos.logo3;
        else if (intro == 'claimexpert')
            path = logos.logo4;
        else if (intro == 'mcg')
            path = logos.logo;
        return (<img style={{ maxHeight: 60, paddingTop: 5 }} src={path} alt='Logo cant load'></img>);
    }

    return (
        <div>
            <AppBar
                position="absolute"
                color="inherit"
                elevation={0}
                sx={{
                    position: 'relative',
                    borderBottom: (t) => `1px solid ${t.palette.divider}`,
                }}
                className="navbar-mcg"
            >
                <Toolbar>
                    <Box>
                        {getNavImage()}
                    </Box>
                </Toolbar>
            </AppBar>
        </div>
    );
}