import * as React from 'react';
import "../assets/css/SignaturePage.css";
import { Button, Grid, Typography } from '@mui/material';
import { withStyles } from "@material-ui/core/styles";
import LoaPreviewButton from '../components/LoaPreviewButton';


export default function SignaturePageEnd(props) {
    const DisclaimerTextTypography = withStyles({
        root: {
            color: "black",
            fontSize: "11px",
        }
    })(Typography);

    return (
        <div>
            <DisclaimerTextTypography gutterBottom sx={{ mx: 2, pt: 6, mt: 6 }} verticalalign="text-bottom" align="center">
                Upon receiving your signature, we will contact your Car Lender to obtain your loan information. Additionally, we will write to your bank to request your statements. This information is essential to help us understand if you may not have been able to afford your monthly car loan repayments.
            </DisclaimerTextTypography>
            <DisclaimerTextTypography gutterBottom sx={{ mx: 2 }} verticalalign="text-bottom" align="center">
                Once we receive the information, we will carefully evaluate it and inform you of our assessment regarding the possibility of a potential claim. You will then have the opportunity to decide whether you would like to pursue a claim, if you do, this may involve engaging our recommended 3rd party CMC or handling the claim yourself.
            </DisclaimerTextTypography>
            <DisclaimerTextTypography gutterBottom sx={{ mx: 2 }} verticalalign="text-bottom" align="center">
                Please note, this is an inquiry, we are not making a complaint on your behalf at this stage. A complaint against your car lender will not commence until further permission is obtained from you. The enquiries we make on your behalf to determine the viability of a potential claim are free!
            </DisclaimerTextTypography>
            <DisclaimerTextTypography gutterBottom sx={{ mx: 2 }} verticalalign="text-bottom" align="center">
                A copy of the documents you need to sign can be found below. Once you have completed them, we will send you a copy for your records. Before signing any documents, we strongly encourage you to thoroughly read the Terms of Business provided.
            </DisclaimerTextTypography>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
                {props.data.lenders && <LoaPreviewButton client={props.client} data={props.data} />}
            </Grid>
        </div>
    );
}
