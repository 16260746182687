import * as React from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import tick from '../assets/tick.jpg';


export default function QuestionList(props) {
    const [data, setData] = React.useState(props.data);

    const setQuestionAnswer = (key, answer) => {
        let newData = { ...data };
        newData[key] = answer;
        setData(newData);
        props.setData(newData);
    }


    const getCarDebtPercentage = (data) => {
        let res = [];
        data.lenders.forEach(element => {
            let percent = (data['vehicle_repay_' + element.id] + (data.debts ?? 0)) / Math.max(1, data.salary) * 100;
            // console.log(data['vehicle_repay_' + element.id], data['vehicle_repay_' + element.id] + (data.debts ?? 0), Math.max(1, data.salary), percent);
            res.push([percent, data['vehicle_repay_' + element.id], data.debts]);
        });
        return res;
    }

    const getFormulaOutcomeText = (data) => {
        let debt_percentage = getCarDebtPercentage(data), show_percentage_debt = [], show_percentage_debt_fail = [];
        for (let i = 0; i < debt_percentage.length; i++) {
            if (debt_percentage[i][0] >= (data['dependent'] == 0 ? 20.0 : 18.0) || isNaN(debt_percentage[i][0])) {
                show_percentage_debt.push([debt_percentage[i], data.lenders[i]]);
            } else {
                show_percentage_debt_fail.push([debt_percentage[i], data.lenders[i]]);
            }
        }
        let text1 = null, text2 = null;
        if (show_percentage_debt.length > 0) {
            text1 = 'We can investigate ' + show_percentage_debt.map((e) => e[1].title).join(', ') +
                ' for potential Unaffordable Finance.';
        }
        if (show_percentage_debt_fail.length > 0) {
            text2 = 'We can investigate ' + show_percentage_debt_fail.map((e) => e[1].title).join(', ') +
                ' for potential Undisclosed Commission.';
        }

        return (<>
            <Grid container justifyContent="center">
                <Grid item>
                    <img src={tick} alt="Successful!" />
                </Grid>
            </Grid>
            <Typography gutterBottom sx={{ mx: 2, my: 1 }} verticalalign="text-bottom" align="center" variant="h4">
                {'Great News!'}
            </Typography>
            {text1 &&
                (<Typography gutterBottom sx={{ mx: 2, my: 1 }} verticalalign="text-bottom" align="center" color="green" >
                    {text1}
                </Typography>)
            }
            {text2 &&
                (<Typography gutterBottom sx={{ mx: 2, my: 1 }} verticalalign="text-bottom" align="center" color="green" >
                    {text2}
                </Typography>)}
            <Typography gutterBottom sx={{ mx: 2, my: 1 }} verticalalign="text-bottom" align="center">
                Finally complete who you Bank with and sign your documents so we can progress your
                enquiry immediately.
            </Typography>
            {/* <Typography gutterBottom sx={{ mx: 2, my: 1 }} verticalalign="text-bottom" align="center">
                Once we receive a response, we will assess the information and inform you if we think you have a
                potential claim. You can then decide if you would like to make a claim (including do it yourself).
                Please note, we will not be making a complaint against your car lender without further permission from
                you.
            </Typography>
            <Typography gutterBottom sx={{ mx: 2, my: 1 }} verticalalign="text-bottom" align="center">
                The enquiries made on your behalf to establish if you have a potential claim are free!
            </Typography>
            <Typography gutterBottom sx={{ mx: 2, my: 1 }} verticalalign="text-bottom" align="center">
                Please read the Terms of Business before you sign.
            </Typography>
            <Typography gutterBottom sx={{ mx: 2, my: 1 }} verticalalign="text-bottom" align="center">
                A copy of the documents that you sign are located below. The completed documents will be sent to you.
            </Typography> */}
        </>);
    }

    let result_html = getFormulaOutcomeText(data)

    return (
        <React.Fragment>
            <Grid container spacing={0}>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={12}>
                        {result_html}
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment >
    );
}
