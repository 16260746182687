import * as React from 'react';
import rawfa from '../assets/loa_fa.txt';
import rawbank from '../assets/loa_bank.txt';
import rawtob from '../assets/tob.txt';
import rawss from '../assets/service_summary.txt';


export default function SignatureFile(props) {
    const getFile = (filetype) => {
        let rawfile = null;
        switch (filetype) {
            case 'fa':
                rawfile = rawfa;
                break;
            case 'bank':
                rawfile = rawbank;
                break;
            case 'tob':
                rawfile = rawtob;
                break;
            case 'ss':
                rawfile = rawss;
                break;
        }
        const queryParameters = new URLSearchParams(window.location.search);
        let newClient = localStorage.getItem("mcgfiledata"), lender = null;
        try {
            newClient = JSON.parse(atob(newClient));
            lender = JSON.parse(atob(queryParameters.get("lender")));
        } catch { }

        fetch(rawfile)
            .then(r => r.text())
            .then(text => {
                const regExp = /\^{1}[\w]{2,3}\,{1}[\w\d_. \\/\-:,\|]*\;/g;
                const matches = [...text.matchAll(regExp)];
                const date = (new Date()).toLocaleDateString();
                matches.map(match => {
                    switch (match[0]) {
                        case "^RD,claim.lender;":
                            text = text.replace(match[0], lender.title);
                            break;

                        case "^RD,client.signature.date;":
                            text = text.replace(match[0], date);
                            break;

                        case "^IMG,client.signature.data,150,75;":
                            text = text.replace("^IMG,client.signature.data,150,75;", "");
                            break;

                        default:
                            text = text.replace(
                                match[0],
                                newClient[match[0].replace("^RD,", "").replace(";", "").replace('client.', '').replace('.', '_')] ?? ''
                            );
                            break;
                    }
                });

                // Replace window
                document.open();
                document.write(text);
                document.close();
            });
    }


    return (<>
        {getFile(props.file)}
    </>);
}