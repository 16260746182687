import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete';
import { debt_range, rent_range } from '../data/SelectRange'


export default function QuestionList(props) {
    const [data, setData] = React.useState(props.data);
    const [dependent, setDependent] = React.useState(props.data.dependent >= 1 ? 1 : props.data.dependent);
    const [rent, setRent] = React.useState(props.data.rent >= 1 ? 1 : props.data.rent);


    const setQuestionAnswer = (key, answer) => {
        let newData = { ...data };
        newData[key] = answer;
        setData(newData);
        props.setData(newData);
    }

    return (
        <React.Fragment>
            <Grid container spacing={0}>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={12}>
                        <Typography gutterBottom sx={{ mx: 2 }} verticalalign="text-bottom" variant="h6" color="blue">
                            If you can't remember, don't worry, please provide your best recollections
                        </Typography>
                        {data.lenders.length > 1 &&
                            <Typography gutterBottom sx={{ mx: 2, fontStyle: 'italic' }} verticalalign="text-bottom" variant="body2" color="blue">
                                *We understand your personal circumstances may vary for each loan. Simply enter the most suitable answers.
                            </Typography>
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <Typography gutterBottom sx={{ mx: 2 }} verticalalign="text-bottom" color={(props.nextAttempt[5] && data.otherdebt == null) ? "red" : "black"}>
                            6. Did you have any other debts that you repaid each month (loans, credit cards, etc)?
                        </Typography>
                    </Grid>
                    <Grid item xs={6} justify="center" align="center">
                        <Button id="vehicle_worth_5000_yes" variant={data.otherdebt !== 1 ? "outlined" : "contained"} sx={{ mx: 1 }} color="success" onClick={() => setQuestionAnswer('otherdebt', 1)}>Yes</Button>
                        <Button id="vehicle_worth_5000_no" variant={data.otherdebt !== 0 ? "outlined" : "contained"} sx={{ mx: 1 }} color="error" onClick={() => setQuestionAnswer('otherdebt', 0)}>No</Button>
                    </Grid>

                    {data.otherdebt == 1 &&
                        <>
                            <Grid item xs={6}>
                                <Typography gutterBottom sx={{ mx: 2 }} verticalalign="text-bottom" color={(props.nextAttempt[5] && !data.debts) ? "red" : "black"}>
                                    7. Approximately how much did you pay to other debts each month?
                                </Typography>
                            </Grid>
                            <Grid item xs={6} justify="center" align="center">
                                <FormControl variant="standard" sx={{ mx: 2, minWidth: 200 }}>
                                    <InputLabel id="debts_select_label">Debts</InputLabel>
                                    <Select
                                        labelId="debts_select_label"
                                        id={"debts_select"}
                                        label={"Other Debts"}

                                        value={data.debts ?? ''}
                                        onChange={(e) => setQuestionAnswer('debts', e.target.value)}
                                    >
                                        {debt_range.map((e) => (<MenuItem value={e.key} key={e.key}>£{e.text}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </>
                    }

                    {/* <Grid item xs={6}>
                        <Typography gutterBottom sx={{ mx: 2 }} verticalalign="text-bottom" color={(props.nextAttempt[5] && data.rent == null) ? "red" : "black"}>
                            When you car finance began, did you pay rent / mortgage?
                        </Typography>
                    </Grid>
                    <Grid item xs={6} justify="center" align="center">
                        <Button id="rent_mortgage_yes" variant={rent !== 1 ? "outlined" : "contained"} sx={{ mx: 1 }} color="success" onClick={() => setRent(1)}>Yes</Button>
                        <Button id="rent_mortgage_no" variant={rent !== 0 ? "outlined" : "contained"} sx={{ mx: 1 }} color="error" onClick={() => setQuestionAnswer('rent', 1) & setRent(0)}>No</Button>
                    </Grid>

                    {rent >= 1 && (<>
                        <Grid item xs={6}>
                            <Typography gutterBottom sx={{ mx: 2 }} verticalalign="text-bottom" color={(props.nextAttempt[5] && !data.rent) ? "red" : "black"}>
                                When you car finance began, how much did you pay rent / mortgage?
                            </Typography>
                        </Grid>
                        <Grid item xs={6} justify="center" align="center">
                            <FormControl variant="standard" sx={{ mx: 2, minWidth: 200 }}>
                                <InputLabel id="rent_select_label">Rent</InputLabel>
                                <Select
                                    labelId="rent_select_label"
                                    id={"rent_select"}
                                    label={"Rent/Mortgage"}

                                    value={data.rent ?? ''}
                                    onChange={(e) => setQuestionAnswer('rent', e.target.value)}
                                >
                                    {rent_range.map((e) => (<MenuItem value={e.key} key={e.key}>£{e.text}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </Grid></>)
                    } */}

                    <Grid item xs={6}>
                        <Typography gutterBottom sx={{ mx: 2 }} verticalalign="text-bottom" color={(props.nextAttempt[5] && data.dependent == null) ? "red" : "black"}>
                            8. Did you have any dependents?
                        </Typography>
                    </Grid>
                    <Grid item xs={6} justify="center" align="center">
                        <Button id="dependent_yes" variant={dependent == 1 ? "contained" : "outlined"} sx={{ mx: 1 }} color="success" onClick={() => setDependent(1)}>Yes</Button>
                        <Button id="dependent_no" variant={dependent == 0 ? "contained" : "outlined"} sx={{ mx: 1 }} color="error" onClick={() => { setQuestionAnswer('dependent', 0); setDependent(0); }}>No</Button>
                    </Grid>
                    {dependent === 1 && <>
                        <Grid item xs={6}>
                            <Typography gutterBottom sx={{ mx: 2 }} verticalalign="text-bottom" color={(props.nextAttempt[5] && !data.dependent) ? "red" : "black"}>
                                9. How many dependent(s) you have?
                            </Typography>
                        </Grid>
                        <Grid item xs={6} justify="center" align="center">
                            <FormControl variant="standard" sx={{ mx: 2, minWidth: 200 }}>
                                <InputLabel id="dependent_select_label">Dependent(s)</InputLabel>
                                <Select
                                    labelId="dependent_select_label"
                                    id="dependent_select"
                                    label="dependent"
                                    value={props.data.dependent ?? ''}
                                    onChange={(e) => setQuestionAnswer('dependent', e.target.value)}
                                >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3+</MenuItem>

                                </Select>
                            </FormControl>
                        </Grid>
                    </>}

                </Grid>
            </Grid>
        </React.Fragment>
    );
}
