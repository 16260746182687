import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';


export default function QuestionList(props) {
    const [data, setData] = React.useState(props.data);

    const setQuestionAnswer = (key, answer) => {
        let newData = { ...props.data };
        newData[key] = answer;
        setData(newData);
        props.setData(newData);
        props.setCarFinance(answer);
    }

    return (
        <React.Fragment>
            <Grid container spacing={0}>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid container spacing={0}>
                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            {data.vehicle_finance !== 0 &&
                                <>
                                    <Grid item xs={6}>
                                        <Typography gutterBottom sx={{ mx: 2 }} verticalalign="text-bottom" color={data.vehicle_finance == 0 ? "red" : "black"}>
                                            1. Have you taken out a PCP/HP vehicle finance in the last 10 years?
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} justify="center" align="center">
                                        <Button id="vehicle_finance_yes" variant={data.vehicle_finance == 1 ? "contained" : "outlined"} sx={{ mx: 1 }} color="success" onClick={() => setQuestionAnswer('vehicle_finance', 1)}>Yes</Button>
                                        <Button id="vehicle_finance_no" variant={data.vehicle_finance == 0 ? "contained" : "outlined"} sx={{ mx: 1 }} color="error" onClick={() => setQuestionAnswer('vehicle_finance', 0)}>No</Button>
                                    </Grid>
                                </>
                            }
                            {data.vehicle_finance === 0 &&
                                <Grid item alignItems="center" xs={12}>
                                    <Typography gutterBottom sx={{ mx: 6 }} verticalalign="text-bottom" color="red" align="center" >
                                        Thank you for your information. Sorry we are not able to help as you don't have car finance
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
