import * as React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import banks from '../data/Bank.json'


export default function QuestionList(props) {
    const [data, setData] = React.useState(props.data);

    const setQuestionAnswer = (key, answer) => {
        let newData = { ...data, ...props.data };
        newData[key] = answer;
        setData(newData);
        props.setData(newData);
    }
    
    return (
        <React.Fragment>
            <Grid container spacing={0}>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={6}>
                        <Typography gutterBottom sx={{ mx: 2 }} verticalalign="text-bottom" color={(props.nextAttempt[7] && !data.banks) ? "red" : "black"}>
                            When your car finance began, who did you bank with?<br />
                            Please Select up to a maximum of 3 banks
                        </Typography>
                    </Grid>
                    <Grid item xs={6} justify="center" align="center">
                        <Autocomplete
                            multiple
                            id="bank-outlined"
                            options={banks}
                            getOptionLabel={(option) => option.title}
                            limitTags={3}
                            filterSelectedOptions={true}
                            onChange={(e, nv) => setQuestionAnswer('banks', nv)}
                            getOptionDisabled={(options) => (data.banks?.length >= 3 ? true : false)}
                            value={data.banks ?? []}
                            key="current-bank-autocomplete"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    key={params.id}
                                    label="Banks"
                                    placeholder="Banks"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment >
    );
}
