import * as React from 'react';
import Typography from '@mui/material/Typography';

const queryParameters = new URLSearchParams(window.location.search)

export default function FormFooter() {

    const getFooterText = () => {
        let intro = queryParameters?.get("introducer");
        let text = '', intro_full = '';
        if (intro == 'impakt') {
            text = 'Impakt Claims';
            intro_full = 'Impakt Claims is a trading style of The Claims Experts Ltd. Registered address is Pioneer House, Ellesmere Port, CH65 1AD (11600861). Impakt Claims are regulated by the Financial Conduct Authority, FRN 836692. ICO Registration number ZA477153';
        }
        else if (intro == 'claimexpert') {
            text = 'ClaimExperts.co.uk';
            intro_full = 'ClaimExperts.co.uk is a trading name of Fairweather Claims Ltd, registered in England and Wales, registration number 12783771. The registered office is Floor 2, M1 3BE. Financial Conduct Authority FRN 935899';
        }
        else if (intro == 'mcg') {
            text = 'My Claim Group';
            intro_full = 'My Claim Group is a Trading name of The Claims Protection Agency Limited, registered in England and Wales Registration Number: 08467014, address is Unit 8a, Ashbrook business park, Wythenshawe, Manchester M22 5LB. The Claims Protection Agency Limited is Authorised and Regulated by the Financial Conduct Authority: FCA No: 836470. Phone: 0161 865 7709';
        } else {
            text = 'The Claims Protection Agency Ltd';
            intro_full = 'My Claim Group is a Trading name of The Claims Protection Agency Limited, registered in England and Wales Registration Number: 08467014, address is Unit 8a, Ashbrook business park, Wythenshawe, Manchester M22 5LB. The Claims Protection Agency Limited is Authorised and Regulated by the Financial Conduct Authority: FCA No: 836470. Phone: 0161 865 7709';
        }
        return (<>
            {/* <Typography variant="body2" color="text.secondary" align="center">
                {'Copyright © '}
                {text + ' '}
                {new Date().getFullYear()}
            </Typography> */}
            <Typography variant="body2" color="text.secondary" align="center">
                {intro_full}
            </Typography>
        </>);
    }

    return (
        <div>
            {getFooterText()}
        </div>
    );
}