import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

export default function ErrorPage() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh'
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid xs={6}>
            <Typography variant="h1">
              404
            </Typography>
            <Typography variant="h6" sx={{ mb: '6'}}>
              The page you’re looking for doesn’t exist.
            </Typography>
            <br/>
            <Button variant="contained" sx={{ pt: '6'}} onClick={() => window.location.href = 'https://myclaimgroup.co.uk/'}>Back Home</Button>
          </Grid>
          <Grid xs={6}>
            <img
              src="https://ui.mantine.dev/_next/static/media/image.11cd6c19.svg"
              alt=""
              width={500} height={250}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}