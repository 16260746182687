import * as React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import thumbup from '../assets/thumbs_up.png'

export default function ThankyouPage(props) {
    const queryString = window.location.search;
    let newdata = { cid: props.data['cid'], dob: props.data['dob'], lenders: props.data['lenders'] };
    let part2_url = '/v2/2' + queryString + '&data=' + btoa(JSON.stringify(newdata));

    return (
        <div>
            <Grid container justifyContent="center">
                <Grid item>
                    <img src={thumbup} alt="Successful!" />
                </Grid>
            </Grid>
            <Typography variant="h5" align="center" color="green">
                Thank you {props.client?.first_name} {props.client?.last_name}.
            </Typography>
            <Typography variant="h5" align="center" sx={{ mt: 2 }} color="green">
                We have sent a confirmation email to {props.client.email}. To speed up the process, click the button below to answer a few additional questions.
            </Typography>
            <Grid container sx={{ mt: 2 }}>
                <Grid item xs={12} justify="center" align="center">
                    <Button align="center" variant="contained" href={part2_url}>Final Step</Button>
                </Grid>
            </Grid>
        </div>
    );
}