import axios from 'axios';

const clientendpoint = 'https://affordability.myclaimgroup.co.uk/api/affordability_verifydob'

export async function verifyFirstStep(validationDob, cid, first_name, last_name) {
    if (validationDob.length !== 10 && cid && first_name && last_name) 
      return false;
    let dob = validationDob.split("/").reverse().join("-");
    let response = await axios.post(clientendpoint, {dob:dob, cid: cid, first_name:first_name, last_name:last_name}, { headers: { 'Content-Type': 'application/json' } });
    if (response.data?.client?.id == cid) {
        return response.data?.client
    } else {
        return false
    }
}

export function verifySecondStep(data) {
    return data.vehicle_finance;
}

export function verifyThirdStep(data) {
    return data.lenders?.length > 0 && !(data.lenders?.length == 1 && data.lenders[0]?.id == 217);
}

export function verifyForthStep(data) {
    return data.lenders[0]?.repayment && 
        ((data.employment && !(['Employed Full Time', 'Employed Part Time', 'Self Employed'].includes(data.employment))) || data.salary >= 0 || (new URLSearchParams(window.location.search)).get("version") == 'b');
}

export function verifyFifthStep(data) {
    return (data.otherdebt == 0 || data.debts) && data.dependent !== null;
}

export function verifySeventhStep(data) {
    return data.banks;
}
