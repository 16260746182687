import * as React from 'react';
import { useState } from "react";
import rawfa from '../assets/loa_fa.txt';
import rawbank from '../assets/loa_bank.txt';
import rawtob from '../assets/tob.txt';
import rawss from '../assets/service_summary.txt';
import { Button, Grid, Typography } from '@mui/material';
import flattenObject from '../utils/FlattenObject'


const convertObjToBase64 = (o) => btoa(JSON.stringify(o));

const showLoaPreviewModal = (client, lender, file) => {
    let newClient = flattenObject(client, 'client');
    let rawfile = null;
    switch (file) {
        case 'fa':
            rawfile = rawfa
            break;
        case 'bank':
            rawfile = rawbank
            break;
        case 'tob':
            rawfile = rawtob
            break;
        case 'ss':
            rawfile = rawss
            break;
    }

    fetch(rawfile)
        .then(r => r.text())
        .then(text => {
            const regExp = /\^{1}[\w]{2,3}\,{1}[\w\d_. \\/\-:,\|]*\;/g;
            const matches = [...text.matchAll(regExp)];

            const date = (new Date()).toLocaleDateString();
            matches.map(match => {
                switch (match[0]) {
                    case "^RD,claim.lender;":
                        text = text.replace(match[0], lender.title);
                        break;

                    case "^RD,client.signature.date;":
                        text = text.replace(match[0], date);
                        break;

                    default:
                        text = text.replace(
                            match[0],
                            newClient[match?.[0].replace("^RD,", "").replace(";", "")] ?? ''
                        );
                        break;
                }
            });
            // document.getElementById('preview').innerHTML = text;
            let wnd = window.open("about:blank", "_blank");
            wnd.document.body.innerHTML = text;
        });
}


const showFilePreview = (client, lender, file) => {
    localStorage.setItem("mcgfiledata", convertObjToBase64(client));
    const currentUrl = window.location.href.replace('/v2/1','') + '&client=' + '' + '&lender=' + convertObjToBase64(lender) + '&file=' + file;
    window.open(currentUrl, '_blank').focus();
}

const pdfClickIcon = (title, text, onClickCallback) => {
    return (<Grid item xs={2}>
        <div
            style={{
                borderRadius: "5px",
                cursor: "pointer",
                display: 'flex',
                padding: '10px',
                color: 'blue',
                flexDirection: "column",
                alignItems: "center",
                gap: "5px"
            }}
            onClick={onClickCallback}
            title={title}
        >
            <img
                style={{
                    width: "25px"
                }}
                src={require("../assets/pdf.png")}
                className={"document-icon"}
                alt=""
            />
            <span style={{ fontSize: ".6em" }}>
                {text}
            </span>
        </div>
    </Grid>)
}

export default function LoaPreviewButton(props) {
    return (
        <>
            {props.data.lenders.map(lender => (
                <React.Fragment key={lender.id}>
                    {pdfClickIcon('Preview Letter of Authority for ' + lender?.title ?? '-', 'Letter of Authority - ' + lender?.title ?? '-', () => showFilePreview(props.client, lender, 'fa'))}
                </React.Fragment>
            ))}
            {props.data.banks.map(bank => (
                <React.Fragment key={bank.id}>
                    {pdfClickIcon('Preview Letter of Authority for ' + bank?.title ?? '-', 'Letter of Authority - ' + bank?.title ?? '-', () => showFilePreview(props.client, bank, 'bank'))}
                </React.Fragment>
            ))}

            {pdfClickIcon('Preview Terms of Business', 'Terms of Business', () => showFilePreview(props.client, null, 'tob'))}
            {pdfClickIcon('Preview Service Summary', 'Service Summary', () => showFilePreview(props.client, null, 'ss'))}

        </>
    );
}

